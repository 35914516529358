<template>
    <v-form>
        <v-list-item>
            <v-combobox
                v-model="pharmacy.name"
                :loading="loading"
                :disabled="loading"
                :error-messages="!validate(pharmacy.name, true) ? ['valid [Pharmacy Name] is required '] : []"
                :items="pharmacies"
                label="Pharmacy Name"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-combobox
                v-model="pharmacy.city"
                :loading="loading"
                :disabled="loading"
                :items="cities"
                @change="update_regions"
                :error-messages="!validate(pharmacy.city, true) ? ['valid [City] is required '] : []"
                label="City"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-combobox
                v-model="pharmacy.region"
                :loading="loading"
                :disabled="loading"
                :items="regions"
                :error-messages="!validate(pharmacy.region, true) ? ['valid [Region] is required '] : []"
                label="Region"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-text-field
                v-model="pharmacy.person"
                :loading="loading"
                :disabled="loading"
                :error-messages="!validate(pharmacy.person, true) ? ['valid [Person Name] is required '] : []"
                label="Person Name"
                color="primary"
            />
        </v-list-item>
        <v-list-item>
            <v-autocomplete 
                :loading="loading"
                :disabled="loading"
                :items="products_names"
                v-model="pharmacy.products" 
                :error-messages="!products_length ? ['valid [Products] is required '] : []"
                multiple 
                chips
                label="Product" 
                color="primary" 
            />
        </v-list-item>
        <v-list-item>
            <v-text-field
                v-model="pharmacy.comment"
                :loading="loading"
                :disabled="loading"
                :error-messages="!validate(pharmacy.comment, false, 1) ? ['[Comment] is not valid '] : []"
                label="Comment"
                color="primary"
            />
        </v-list-item>
    </v-form>
</template>

<script>
export default {
    name: "PharmacySector",
    props: {
        pharmacy: {
            type: Object,
            required: true,
        },
    },
    methods: {
        validate(value, required = false, length = 3) {
            return (typeof value == "string" && value?.length >= length) || (!required && value?.length == 0);
        },
        async update_regions(v){
            try {
                console.log("updating regions", v)
                this.regions.splice(0)
                if(!v){return}
                const regions = (await this.$api.post("/address/region/fetch/names_by_city", {
                    city_name:v
                })).data.result.names
                this.regions.push(...regions)
            } catch (error) {
                console.log(error)
            }
        }
    },
    data() {
        return {
            establishments: [],
            specialities: [],
            doctors: [], 
            products_names: [], 
            pharmacies: [], 
            cities: [], 
            regions: [], 
            loading: false, 
        };
    },
    async mounted() {
        try {
            const establishments = (await this.$api.post("/fetch/establishments")).data.results
            this.establishments.splice(0)
            this.establishments.push(...establishments)


            const doctors = (await this.$api.post("/doctor/fetch/names")).data.result.names
            this.doctors.splice(0)
            this.doctors.push(...doctors)

            const specialities = (await this.$api.post("/speciality/fetch/names")).data.result.names
            this.specialities.splice(0)
            this.specialities.push(...specialities)


            const products_names = (await this.$api.post("/product/fetch/names")).data.result.names
            this.products_names.splice(0)
            this.products_names.push(...products_names)

            const cities = (await this.$api.post("/address/city/fetch/names")).data.result.names
            this.cities.splice(0)
            this.cities.push(...cities)

            const pharmacies = (await this.$api.post("/pharmacy/fetch/names")).data.result.names
            this.pharmacies.splice(0)
            this.pharmacies.push(...pharmacies)
        } catch (error) {
            console.log(error)
        }
    },
    computed: {
        products_length(){
            return !!this.pharmacy?.products?.length
        },
        valid() {
            let condition = true;
            condition &= this.validate(this.pharmacy.name, true);
            condition &= this.validate(this.pharmacy.city, true);
            condition &= this.validate(this.pharmacy.region, true);
            condition &= this.validate(this.pharmacy.person, true);
            condition &= this.validate(this.pharmacy.comment, false, 1);
            condition &= !!this.pharmacy?.products?.length;
            this.$set(this.pharmacy, "valid", condition);
            return condition;
        },
    },
    watch: {
        valid() {},
    },
};
</script>

<style></style>
